import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import { ThemeContext } from '../layouts'
import CourseList from '../components/Course/CourseList'
import Seo from '../components/Seo'

const CoursesPage = props => {
  const {
    data: {
      courses: { edges: courses },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => <CourseList courses={courses} theme={theme} />}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} pathPrefix="/courses/" />
    </React.Fragment>
  )
}

CoursesPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default CoursesPage

//eslint-disable-next-line no-undef
export const query = graphql`
  query CoursesQuery {
    courses: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//courses/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`
