import PropTypes from 'prop-types'
import React from 'react'
import Snapshot from './Snapshot'

const CourseList = props => {
  const { courses, theme } = props

  return (
    <React.Fragment>
      <main className="main">
        <h1>Course Directory</h1>
        <ul>
          {courses.map(course => {
            const {
              node,
              node: {
                fields: { slug }
              }
            } = course
            return <Snapshot key={slug} course={node} theme={theme} />
          })}
        </ul>
      </main>

      {/* --- STYLES --- */}
      <style jsx>{`
        .main {
          padding: 0 ${theme.space.inset.default};
        }

        ul {
          list-style: none;
          margin: 0 auto;
          padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
        }

        @above tablet {
          .main {
            padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
          }
          ul {
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @above desktop {
          ul {
            max-width: ${theme.text.maxWidth.desktop};
          }
        }

        h1 {
          font-size: ${theme.font.size.xl};
          margin-top: ${theme.space.l};
          font-weight: ${theme.font.weight.standard};
          text-align: center;
          display: block;
          letter-spacing: 0;
        }

        @from-width tablet {
          h1 {
            font-size: ${`calc(${theme.font.size.xl} * 1.2)`};
            margin-top: ${theme.space.l};
          }
        }

        @from-width desktop {
          h1 {
            font-size: ${`calc(${theme.font.size.xl} * 1.4)`};
            margin-top: ${theme.space.xl};
          }
        }
      `}</style>
    </React.Fragment>
  )
}

CourseList.propTypes = {
  courses: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
}

export default CourseList
