import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import getOr from 'lodash/fp/getOr'
import config from '../../../content/meta/config'

const coverSelector = getOr(null, 'frontmatter.cover.childImageSharp.resize.src')
const fluidSelector = getOr(null, 'frontmatter.cover.children[0].fluid.src')

const Seo = props => {
  const { data, facebook, pathPrefix = '' } = props
  const postTitle = ((data || {}).frontmatter || {}).title
  const postDescription = ((data || {}).frontmatter || {}).description
  const postSlug = ((data || {}).fields || {}).slug

  const title = postTitle ? `${postTitle} - ${config.shortSiteTitle}` : config.siteTitle
  const description = postDescription ? postDescription : config.siteDescription
  const image = coverSelector(data) || fluidSelector(data) || config.siteImage

  const url = `${config.siteUrl}${pathPrefix}${postSlug || ''}`

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
        prefix: 'og: http://ogp.me/ns#'
      }}
    >
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      {/* Facebook tags */}
      <meta property="fb:app_id" content={facebook.appId} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:creator"
        content={config.authorTwitterAccount ? config.authorTwitterAccount : ''}
      />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  )
}

Seo.propTypes = {
  data: PropTypes.object,
  facebook: PropTypes.object.isRequired,
  pathPrefix: PropTypes.string
}

export default Seo
